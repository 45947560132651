// Farbpalette
$color-primary: #508ff8; // Blau Primary
$color-background: #f7f8fc; //Hellblau Primary Background
$color-background-secondary: #f1f4ff; // Hellblau für Tabelle oder Component Background
$color-text: #022751; // Dunkelblau für normaler Text
$color-text-secondary: #a6afd0; // Hellblau für Card Überschrift
$color-card-background: #edf0fb;
$color-notification-card-background: #fae7b8;
$color-link-background: #f8faff;
$primary-button-hover: #427adb;
$secondary-button-hover: #f1f4ff;

// Schriftgrößen
$font-size-xsmall: 12px; // Extra kleine Schriftgröße, z.B. für Fußnoten
$font-size-small: 14px; // Kleine Schriftgröße z.B. für Tabelle
$font-size-base: 16px; // Basis-Schriftgröße
$font-size-large: 20px; // Größere Schriftgröße, z.B. für Überschriften
$font-size-xlarge: 24px; // Extra große Schriftgröße, z.B. für große Überschriften
$font-size-xxlarge: 35px;

// Schriftarten
$font-family-base: 'OpenSans', sans-serif;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Schatten
$button-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
$blue-shadow: 0px 3.595px 10.785px 1.797px rgba(80, 143, 248, 0.18);
$dark-blue-shadow: 0px 5px 10px 1px rgba(20, 77, 171, 0.18);

// Border
$border-radius-medium: 8px;
$border-radius-small: 4px;
